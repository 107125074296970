import React, { useState, useEffect } from "react";
import { isAfter } from "date-fns";

import SEO from "components/Seo";
import Header from "components/Header";
import Footer from "components/Footer";

import LayoutMain from "components/layout/layoutMain";
import LayoutPanel from "components/layout/layoutPanel";

import AddressSearchInput from "components/common/AddressSearchInput";
import Image from 'components/common/Image';
import Faq from 'components/common/Faq';

import ProductsInfo from "components/cards/ProductsInfo";
import ProductHighlight from 'components/cards/ProductHighlight';
import RacInfo from "components/cards/RacInfo";
import CarbonOffsetInfo from "components/cards/CarbonOffsetInfo";
import FlybuysInfo from "components/cards/FlybuysInfo";
import AwardsInfo from 'components/cards/AwardsInfo';
import SignupNgInfo from "components/cards/SignupNgInfo";
import NgRacPromoInfo from "components/cards/NgRacPromoInfo";

import faqDataOffer from 'constants/components/accordion-list/natural-gas-faq';

import { isLpgDemergerOn } from 'constants/global';

function NaturalGasProductPage() {
	const [isPromoRac50Active, setIsPromoRac50Active] = useState(true);
  
	useEffect(() => {
  
		const currentDate = new Date();

		// racbonus 50
		const promoRac50Start = process.env.RACBONUS50_START.split('-');
		const promoRac50End = process.env.RACBONUS50_EXPIRE.split('-');
			
		const promoRac50StartDate = new Date(promoRac50Start[0], promoRac50Start[1]-1, promoRac50Start[2]);
		const promoRac50EndDate = new Date(promoRac50End[0], promoRac50End[1]-1, promoRac50End[2], promoRac50End[3] ? promoRac50End[3] : '', promoRac50End[4] ? promoRac50End[4] : '');
	
		const isPromoRac50Started = isAfter(currentDate, promoRac50StartDate);
		const isPromoRac50Ended = isAfter(currentDate, promoRac50EndDate);
	
		if (!isPromoRac50Started || isPromoRac50Ended) {
			setIsPromoRac50Active(false);
		}   
	}, []);

	return (
		<>
            <SEO title="Natural Gas Supplier | WA's Local Provider - Kleenheat" 
				 titleBespoke="true"
            	 description="Join WA's local natural gas supplier. Enjoy award-winning local service and ongoing value with Kleenheat. Find a plan to suit your needs today."
        	/>
            <Header />
            <LayoutMain>

            	{/* hero banner */}
            	<SignupNgInfo theme="mer-theme--cyan"
                              background="mer-bg--primary-cyan"
                              padding="mer-py-lg" />

				<ProductHighlight panelBackground="mer-bg--ui-light"
								  panelPadding="mer-pt-lg" />
			
	            <ProductsInfo panelBackground="mer-bg--ui-light"
	            			  panelPadding="mer-py-lg"
	            			  id="pricing" />
				
				<RacInfo  panelBackground="mer-bg--ui-light"
	            		  panelPadding="mer-py-de"
            			  ngAvailable={true} />

				<FlybuysInfo panelBackground="mer-bg--ui-light"
	            			 panelPadding="mer-py-de" />

				<CarbonOffsetInfo  panelBackground="mer-bg--ui-light"
	            		  		   panelPadding="mer-pt-de mer-pb-ju" />

				<NgRacPromoInfo
						theme={`mer-theme--cyan${isPromoRac50Active ? ' d-block': ' d-none'}`}
						background="mer-bg--primary-cyan"
						padding="mer-py-lg" />

				<AwardsInfo panelBackground="mer-bg--gs-grey-lighter" 
                    		panelPadding="mer-py-de" />

				<Faq panelBackground="mer-bg--ui-light"
		  			 panelPadding="mer-py-lg"
		  			 id="faq"
		  			 data={faqDataOffer} />

			 	{/* address search */}
            	<LayoutPanel theme="mer-theme--light"
            				 padding="mer-py-lg"
            				 col="flex-sm-row-reverse"
                			 background="mer-bg--gs-grey-lighter"
		                	 id="service-availability" >
	                <div className="container">
	                    <div className="row mer-panel justify-content-center">

	                        <div className="col-10 col-sm-6 col-md-7 col-lg-5">
	                            <div className="content">
	                                <h2 className="mer-typography--headline3">
	                                	Is natural gas available in my area?
	                                </h2>
	                                <p>Kleenheat can supply natural gas to homes connected to WA’s distribution network across the Perth metropolitan area and spanning Geraldton to Busselton.</p>
	                            </div>
	                            
	                           	<form className="mt-3 mt-lg-5 mb-0 mer-text--size-lg">
	                                <fieldset>
	                                    <legend>Check availability at your address</legend>
	                                    <div className="row">
	                                        <div className="col-12">
	                                            <AddressSearchInput searchButtonText="Check address" isSsfRedirect={isLpgDemergerOn} />
	                                        </div>
	                                    </div>
	                                </fieldset>
	                            </form>
	                        </div>

	                        <div className="mer-panel__images col-8 col-sm-4 col-lg-3 offset-sm-1">

	                            <Image className="mer-panel__image"
	                                 src="/icons/flowmoji/flowmoji-search.svg"
	                                 alt="Flowmoji search"
	                          />
	                        </div>
	                    </div>
	                </div>
	            </LayoutPanel>

		    </LayoutMain>
			<Footer />
		</>
	)
}

export default NaturalGasProductPage;